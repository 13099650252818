@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');



/* General Styles */
body {
    font-family: "Moderustic";
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


nav ul {
  display: flex;
  gap: 30px;
}

nav ul li {
  display: inline;
}

nav ul li a {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

nav ul li a:hover {
  color: #ffc107;
}

/* Header Styles */
header {
  background-color: #1a1a1a;
  padding: 5px;
}

.navbar-brand.logo {
  color: white;
  font-family: Moderustic;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}

.navbar-nav .nav-link {
  color: white;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover {
  color: #f0f0f0;
  transform: scale(1.1);
}

.offcanvas-header .offcanvas-title {
  color: white;
}

.offcanvas-header .btn-close {
  background: none;
}
.offcanvas-header {
  position: relative;
}
.offcanvas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc; /* Opcional: para agregar una línea debajo del encabezado */
}

.offcanvas-title {
  margin: 0;
  font-size: 1.25rem;
  color: white;
}

.btn-close {
  background: none; /* Sin fondo para el botón */
  border: none; /* Sin borde para el botón */
  padding: 0; /* Sin relleno */
  display: flex; /* Usar flexbox para alinear el contenido */
  align-items: center; /* Centrar verticalmente */
  justify-content: center; /* Centrar horizontalmente */
  width: 2rem; /* Tamaño del botón */
  height: 2rem; /* Tamaño del botón */
  cursor: pointer; /* Cambia el cursor para indicar que es clickeable */
}

.btn-close svg {
  fill: white; /* Color del ícono */
  width: 1.5rem; /* Tamaño del ícono */
  height: 1.5rem; /* Tamaño del ícono */
}






/* Hero Section */
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 150px 20px;
  color: white;
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.hero-collage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  position: relative; /* Asegura que el texto se posicione sobre el collage */
}

.hero-collage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Ocultar las imágenes adicionales en pantallas pequeñas */
@media (max-width: 768px) {
  .hero-collage {
    grid-template-columns: 1fr;
  }

  .hero-collage img:not(:first-child) {
    display: none; /* Oculta todas las imágenes excepto la primera */
  }
}

.hero-content {
  position: absolute; /* Posiciona el texto sobre el collage */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Ajusta la posición para centrar */
  z-index: 10; /* Asegura que el texto esté por encima del collage */
  text-align: center;
}

.hero-content h1 {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-content p {
  font-size: 24px;
  margin: 10px 0 20px; /* Ajusta el margen entre el párrafo y el botón */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-content button {
  background-color: #ffc107;
  color: black;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.hero-content button:hover {
  background-color: #e0a800;
}




/* Testimonials Section */
.testimonials {
  background-color: #f1f1f1;
  padding: 50px 20px;
  text-align: center;
}

.testimonials h2 {
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: bold;
  color: #333;
}

.testimonials p {
  font-size: 18px;
  font-style: italic;
  margin: 20px auto;
  width: 60%;
  color: #666;
}

/* Footer Styles */
footer {
  background-color: #1a1a1a;
  color: white;
  text-align: center;
  padding: 20px;
}

footer p {
  margin: 5px 0;
  font-size: 16px;
}

footer a:hover {
  color: #ffc107;
}

/* src/components/Product.css */

.product-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.product-image {
  flex: 1;
  padding-right: 50px;
}

.product-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   height: auto;
}

.product-details {
  flex: 1;
  padding-left: 50px;
}

.product-details h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
}

.product-details p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;
  line-height: 1.5;
}

.product-details h2 {
  font-size: 28px;
  margin-bottom: 30px;
  color: #000;
}

.quantity-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.quantity-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Permite que los botones se distribuyan en varias filas si es necesario */
}

.buy-button-top {
  width: 180px;
  text-align: center;
  background-color: #007bff;
  color: black;
  padding: 12px 0;
  font-size: 25px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  font-weight: 600;
}

.quantity-display {
  width: 80px;
  text-align: center;
  display: inline-block;
  padding: 8px;
  font-size: 22px;
  border: 3px solid black;
  border-radius: 20%;
  font-weight: bold;
}

@media (max-width: 480px) {
  .quantity-row {
    gap: 10px; /* Reduce el espacio entre los botones en pantallas pequeñas */
  }

  .buy-button-top {
    width: 45%; /* Asegura que dos botones puedan estar en la misma línea */
    max-width: 160px; /* Ancho máximo en pantallas pequeñas */
    font-size: 20px; /* Reduce el tamaño de la fuente para pantallas pequeñas */
    padding: 10px 0; /* Ajusta el padding para pantallas pequeñas */
  }

  .quantity-display {
    width: 60px; /* Reduce el ancho del display en pantallas pequeñas */
    font-size: 18px; /* Ajusta el tamaño de la fuente */
  }
}


.buy-button:hover {
  background-color: #e0a800;
}

@media (max-width: 768px) {
  .product-container {
      flex-direction: column; /* Cambia a diseño vertical */
      align-items: center; /* Centra los elementos */
  }

  .product-details {
    width: 100%; /* Ocupa todo el ancho disponible */
    padding-left: 0; /* Elimina el padding izquierdo */
    text-align: center; /* Centra el contenido */
  }

  .product-image {
    max-width: 100%; /* El carrusel ocupa todo el ancho disponible */
    padding-right: 0; /* Elimina el padding derecho */
    margin-bottom: 20px; /* Agrega espacio debajo del carrusel */
  }

  .product-details h1 {
    font-size: 28px;
  }

  .product-details p {
    font-size: 16px;
  }

  .product-details h2 {
    font-size: 24px;
  }

  .buy-button {
    width: 100%;
    padding: 15px 0;
  }
}
.terms-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.terms-container label {
  font-size: 16px;
  color: #333;
}

.terms-container a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.terms-container a:hover {
  text-decoration: underline;
}
/* Estilos del contenedor del carrusel */
.product-image {
  max-width: 45%; /* Asegura que el contenedor del carrusel ocupe todo el espacio permitido */
  margin: 0; /* Centra el carrusel */
  padding-right: 20px;
}

/* Estilos específicos para las imágenes */
.product-image img {
  width: 100%; /* Asegura que las imágenes se ajusten al ancho disponible */
  height: auto; /* Mantiene la proporción de las imágenes */
  max-height: 80vh;
  max-height: 600px; /* Opcional: establece una altura máxima para evitar imágenes demasiado grandes */
  object-fit: contain; /* Asegura que la imagen se adapte sin recortarse */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Agrega un efecto de sombra para mayor estética */
}

/* Estilo responsive adicional para pantallas pequeñas */
@media (max-width: 768px) {
  .product-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 10px; /* Reduce la altura máxima de las imágenes en pantallas pequeñas */
  }
}
@media (max-width: 480px) {
  .product-image img {
    max-height: 100vh; /* Asegura que las imágenes en teléfonos móviles ocupen más altura */
  }
}
/* Ajustes específicos de Swiper */
.swiper-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* Ajustes para las imágenes en pantallas pequeñas */
@media (max-width: 768px) {
  .product-image {
    max-width: 100%; /* Asegura que el contenedor del carrusel ocupe todo el ancho disponible */
    padding-right: 0; /* Elimina el padding derecho */
    margin-bottom: 20px; /* Agrega espacio debajo del carrusel */
  }

  .product-image img {
    width: 100%; /* Asegura que las imágenes ocupen todo el ancho disponible */
    height: auto; /* Mantiene la proporción de las imágenes */
    max-height: 80vh; /* Ajusta la altura máxima a un porcentaje de la altura de la pantalla */
    object-fit: contain; /* Asegura que la imagen se adapte sin recortarse */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Agrega un efecto de sombra para mayor estética */
  }
}

/* Ajustes adicionales para pantallas aún más pequeñas (dispositivos móviles) */
@media (max-width: 480px) {
  .product-image {
    max-width: 100%; /* Asegura que el contenedor del carrusel ocupe todo el ancho disponible */
    padding-right: 0; /* Elimina el padding derecho */
  }

  .product-image img {
    width: 100%; /* Las imágenes ocupan todo el ancho disponible */
    height: 60vh; /* Ajusta la altura de las imágenes para que sean más grandes (proporcional) */
    object-fit: contain; /* Asegura que la imagen se mantenga proporcional sin recortes */
    max-height: none; /* Elimina el límite de altura para que ocupe más espacio */
  }
}




/* Login */

/* src/App.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.login-form {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333333;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666666;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 1rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: #0056b3;
}

.forgot-password {
  text-align: center;
  margin-top: 1rem;
  color: #666666;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

/* src/components/PanelAdmin.css */
.panel-admin {
  padding: 100px;
  background-color: #f8f9fa;
  border-radius: 8px;
  max-width: 700px;
  margin: 200px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.panel-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.panel-button {
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.panel-button:hover {
  background-color: #0056b3;
}

/* src/components/PublicacionAdm.css */

.publicacion-adm {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.publicacion-adm h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.form-group input[type="number"] {
  -moz-appearance: textfield;
}

.form-group input[type="number"]::-webkit-inner-spin-button,
.form-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.image-preview {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/*Mensaje de Confirmacion PublicacionAdm*/
.confirmation-message {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50; /* Color de fondo verde */
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  animation: fadeInOut 3s ease-in-out; /* Animación */
}

/* Animación para que el cartel aparezca y desaparezca */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
/* SORTEO */
.sorteo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 80vh;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sorteo-section {
  text-align: center;
  margin-top: 20px;
}

.sorteo-button {
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sorteo-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.sorteo-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.sorteo-display {
  margin-top: 20px;
  font-size: 40px;
  font-weight: bold;
  padding: 20px; /* Añade más espacio alrededor del número */
  border-radius: 10px; /* Ajusta el radio del borde */
}

.spinning-number {
  color: #007bff;
  animation: spin 0.1s linear infinite;
}

.sorteo-result {
  margin-top: 20px;
  padding: 20px;
  background-color: #4caf50;
  color: white;
  border-radius: 8px;
  font-size: 22px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.zoom-effect {
  animation: zoom 0.5s ease-out forwards;
}

.flash-effect {
  animation: flash 0.5s ease-out forwards; /* Añade un destello de luz */
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

@keyframes spin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

/* Luces intermitentes */
.spinning {
  border: 4px solid;
  border-color: #ff0000 #00ff00 #0000ff #ffff00;
  animation: lights 0.5s linear infinite;
}

@keyframes lights {
  0% {
    border-color: #ff0000 #00ff00 #0000ff #ffff00;
  }
  100% {
    border-color: #ffff00 #0000ff #00ff00 #ff0000;
  }
}

/* Barra de progreso */
.progress-bar-container {
  margin-top: 20px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.progress-bar {
  height: 10px;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.1s ease;
}

/* Añadir un destello de luz */
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}


.success-page {
  text-align: center;
  margin: 200px;
}

.success-page h1 {
  color: green;
}

.success-page button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.success-page button:hover {
  background-color: #45a049;
}

/* src/components/Failure.css */
.failure-page {
  text-align: center;
  margin: 200px;
}

.failure-page h1 {
  color: red;
}

.failure-page button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.failure-page button:hover {
  background-color: #d32f2f;
}

/* src/components/Pending.css */
.pending-page {
  text-align: center;
  margin: 200px;
}

.pending-page h1 {
  color: orange;
}

.pending-page button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pending-page button:hover {
  background-color: #fb8c00;
}

.sorteo-actual-container {
  text-align: center;
  padding: 70px;
}

.export-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px auto;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #45a049;
}

.transaction-table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}

.transaction-table th, .transaction-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.transaction-table th {
  background-color: #4CAF50;
  color: grey;
}

.transaction-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.transaction-table tr:hover {
  background-color: #ddd;
}
.sorteo-actual-container {
  padding: 20px;  
}

.export-button, .load-more-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.export-button:hover, .load-more-button:hover {
  background-color: #218838;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transaction-table th, .transaction-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.transaction-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.barra-de-progreso {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.progress-container {
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease;
}

input[type="number"] {
  padding: 5px;
  width: 50px;
}

button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}


/* BarraDeProgreso.css */
.barra-de-progreso {
  width: 100%;
  margin-top: 20px; /* Espacio entre el producto y la barra de progreso */
}

.progress-container {
  width: 100%;
  height: 20px;
  background-color: #f3f3f3;  
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50; /* Color de la barra de progreso */
  transition: width 0.3s; /* Suaviza el cambio de ancho */
}

/* App.css */

.admin-barra-progreso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ocupa toda la altura de la pantalla */
  padding: 20px; /* Padding adicional */
  box-sizing: border-box; /* Incluye el padding en el tamaño total */
  background-color: #f0f0f0; /* Color de fondo claro */
  border: 1px solid #ccc; /* Bordes para el contenedor */
  border-radius: 8px; /* Bordes redondeados */
}

.input-button-container {
  display: flex;
  flex-direction: column; /* Cambiar a columna para centrar verticalmente */
  align-items: center;
  margin-top: 20px; /* Espacio entre el título y el contenedor */
}

.porcentaje-input-adm {
  width: 100px; /* Ancho fijo para el input */
  padding: 10px; /* Padding interno */
  border: 1px solid #ccc; /* Borde del input */
  border-radius: 4px; /* Bordes redondeados */
  margin-bottom: 10px; /* Espacio entre el input y el botón */
  font-size: 16px; /* Tamaño de fuente */
}

.guardar-button {
  padding: 10px 20px; /* Espaciado interno del botón */
  background-color: #007bff; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  border: none; /* Sin borde */
  border-radius: 4px; /* Bordes redondeados */
  cursor: pointer; /* Cursor de mano */
  font-size: 16px; /* Tamaño de fuente del botón */
  transition: background-color 0.3s; /* Efecto de transición */
}

.guardar-button:hover {
  background-color: #0056b3; /* Color al pasar el mouse */
}

/* Asegúrate de que el botón sea visible */
/* Asegúrate de que todo esté centrado */
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa toda la altura de la ventana */
  text-align: center;
}

/* Botón principal para borrar */
.btn-delete {
  background-color: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Cuadro de confirmación */
.confirmation-box {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
}

/* Grupo de botones */
.button-group {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* Botón de confirmar */
.btn-confirm {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Botón de cancelar */
.btn-cancel {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.success-message {
  background-color: #d4edda; /* Color de fondo verde claro */
  color: #155724; /* Color del texto oscuro */
  border: 1px solid #c3e6cb; /* Borde verde */
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center; /* Centrar el mensaje */
  align-items: center; /* Centrar verticalmente */
  font-weight: bold; /* Negrita para el texto */
}
.success-message {
  background-color: #d4edda; /* Color de fondo verde claro */
  color: #155724; /* Color del texto oscuro */
  border: 1px solid #c3e6cb; /* Borde verde */
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center; /* Centrar el mensaje */
  align-items: center; /* Centrar verticalmente */
  font-weight: bold; /* Negrita para el texto */
}
/* Carrucel */
.image-carousel {
  display: flex;
  overflow-x: scroll;
  gap: 10px;
}

.image-preview {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 8px;
}
